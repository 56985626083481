var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 mt-5 pt-3"},[_c('div',{staticClass:"row"},[_c('div',{class:{
        [_vm.$style.card__register]: true,
      }},[_c('div',{class:{
          [_vm.$style.card__register__image]: true,
        }},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"width":"200","height":"63"}})]),_vm._m(0),_c('div',{staticClass:"d-flex justify-content-center align-items-center pbz-font body-md-regular my-3 text-center",staticStyle:{"color":"#999999 !important"}},[_vm._v(" "+_vm._s(_vm.registerStatus)+" ")]),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
          [_vm.$style.button__submit]: true,
        },attrs:{"size":"large"},on:{"click":function($event){$event.preventDefault();return (() => _vm.toDashboardPage()).apply(null, arguments)}}},[(!_vm.loading)?_c('div',[_vm._v(" Go to Powerbiz! ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center mt-5 mb-3 text-center",staticStyle:{"color":"#1A1A1A !important"}},[_c('div',{staticClass:"pbz-font subtitle-md-bold"},[_vm._v(" Congratulation! ")]),_c('div',{staticClass:"pbz-font subtitle-sm-medium"},[_vm._v(" Registration Completed ")])])
}]

export { render, staticRenderFns }